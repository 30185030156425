$(document).ready(function()
{
    $(document).on("click", ".help, .help-button", function()
    {
        $(this).dialogOpen('<div>' + $(this).data('help') + '</div>', {
            'position' : 'right-top',
            'stem' : true,
            'id' : 'help-dialog'
        });
    });
});
