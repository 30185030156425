function SbEvents()
{
    this._Sb_events = {};

    this.on = function(events, callback)
    {
        var list = events.split(/[ ]+/);
 
        for (var i = 0, event; (event = list[i]); i++) {
 
            if (!this._Sb_events[event]) {
                this._Sb_events[event] = [];
            }

            this._Sb_events[event].push(callback);
        }
        
        return this;
    };

    this.trigger = function(event, data)
    {
        if (!this._Sb_events[event]) {
            return;
        }

        var obj = {
            type: event
        };
        
        for (var i = 0, evt; (evt = this._Sb_events[event][i]); i++) {
            evt._event = obj;
            evt.call(this, data);
            delete evt._event;
        }
    };
}
