// Help generate valid URL
jqueryForm.addEnhancement(function()
{
    if (this.form.data('enhanced')) {
        return;
    }
    this.form.on('blur', 'input[type=url]', function()
    {
        this.value = this.value.trim();
        
        if (this.value.length === 0) {
            return;
        }

        if (this.value.match(/^http/)) {
            return;
        }      
        
        if (this.value[0] == '/') {
            if (this.value[1] == '/') {
                return;
            }
            
            this.value = location.protocol + '//' + location.hostname + this.value;
        } else if (this.value.substr(0, location.hostname.length) == location.hostname) {
            this.value = location.protocol + '//' + this.value;
        } else {
            this.value = 'http://' + this.value;
        }
        
        $(this).checkValidity(true);
    });
});
