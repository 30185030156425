function SbExpander(selector, options)
{
    var self = this;
    this.cont = $(selector);
    this.head = null;
    this.body = null;
    this.origin = location.pathname + location.search;
    $.extend(this, new SbEvents());

    this.options = {
        path:  location.pathname, // starting path
        show: function(head, body)
        {
            var self = this;
            body.finish().slideDown({
                start: function()
                {
                    this.classList.add('sliding');
                },
                complete: function()
                {
                    this.classList.remove('sliding');
                    self.scroll(head, body);
                }
            });
        },

        hide: function(head, body)
        {
            body.finish().slideUp({
                start: function()
                {
                    this.classList.add('sliding');
                },
                complete: function()
                {
                    this.classList.remove('sliding');
                }
            });
        }

    };

    if (arguments.length >= 2) {
        $.extend(this.options, options);
    }

    this.cont
    .on("click", ".expander-head", function(evt)
    {
        var link = $(this);
        var article_type = link.data('type');

        self.clear(link);

        var expander = $("#" + link.attr("aria-controls"));

        var showState = {
            type: "expander",
            id: link.prop('id'),
            mode: "show",
            path: link.attr('href'),
            origin: self.origin,
        };

        var hideState = {
            type: "expander",
           // id: head.prop('id'),
            id: link.prop('id'),
            mode: "hide",
            path: location.pathname,
            origin: self.origin,
        };


        var newState;

        if (link.attr("aria-expanded") == "false") {
            if (!history.state) {
                history.replaceState(hideState, "", location.href);
            } else if (history.state.type != "expander") {
                newState = history.state;
                newState.expander = hideState;
                history.replaceState(newState, "", location.href);
            }

            history.pushState(showState, "", link.attr('href'));
        /*
            if (article_type === "inline") {
                history.pushState(showState, "", '#' + link.attr('id'));
            } else {
                history.pushState(showState, "", link.attr('href'));
            }
        */

            self.show(link);
        } else {
            if (!history.state) {
                history.replaceState(showState, "", location.href);
            } else if (history.state.type != "expander") {
                newState = history.state;
                newState.expander = showState;
                history.replaceState(newState, "", location.href);
            }

            history.pushState(hideState, "", self.options.path); 
            self.hide(link);
        }
 
        evt.preventDefault();
    });

    $(window).on("popstate", function(evt)
    {
        var e = evt.originalEvent;
        var state = e.state;

        self.setState(state);
    });

    this.setState = function(state)
    {
        if (!state) {
            // state for starting page is null
            self.cont.find(".is-expanded").each(function()
            {
                self.hide($(this));
            });
        
        /*
            var data = {
                type: "expander",
                mode: "reset",
                origin: self.origin,
            };

            history.replaceState(data, "", self.origin);
        */

            return;

        } else if (state.type != "expander") {
            if (state.expander) {
                state = state.expander;
            } else {
                return;
            }
        }

        var link = self.cont.find("#" + state.id);

        if (link.length === 0) {
            return;
        }

        self.clear(link);

        if (state.origin !== self.origin) {
            if (location.pathname != state.path) {
                location.reload();
                return;
            } else {
                return;
            }
        }

        switch (state.mode) {
        case 'reset':
            self.cont.find(".is-expanded").each(function()
            {
                self.hide($(this));
            });

            break;
        case 'show':
            if (link.hasClass("is-active") === false) {
                self.show(link);
            }
            break;
        case 'hide':
            self.hide(link);
            break;
        }
    };

    this.clear = function(link)
    {
        self.cont.find(".is-expanded")
        .each(function()
        {
            var oldLink = $(this);

            if (link && link.attr('id') == oldLink.attr('id')) {
                return;
            }

            self.hide(oldLink);
        });
    };

    this.show = function(head)
    {
        var body = $("#" + head.attr("aria-controls"));

        head.attr("aria-expanded", "true");
        head.addClass('is-expanded');

        this.trigger("beforeshow", {
            head: head,
            body: body
        });
        this.options.show.call(this, head, body);
    };

    this.hide = function(head)
    {
        var body = $("#" + head.attr("aria-controls"));

        head.attr("aria-expanded", "false");
        head.removeClass('is-expanded');

        this.trigger("beforehide", {
            head: head,
            body: body
        });
        this.options.hide.call(this, head, body);
    };

    this.scroll = function(head, body)
    {
        var winScrollTop = $(window).scrollTop();
        var winBottom = winScrollTop + $(window).height();
                
        var expTop = body.offset().top;
        var expBottom = expTop + body.height();
 
        if (expTop < winScrollTop || expBottom > winBottom) {  
            $.scrollTo(head, 200, {offset: -($(window).height() * 0.2)});
        /*
            $("html, body").animate({
               scrollTop: head.offset().top
            }, "slow");
        */
        }                
    };

    $(document).ready(function()
    {
        var hash = window.location.hash;

        if (hash.match(/^#[a-zA-Z0-9_-]+$/)) {
            var link = self.cont.find(hash);
            if (link.length) {
                link.trigger('click');
             /*
                link.attr("aria-expanded", "true");
                $("#" + link.attr("aria-controls")).css("display", "");
            */
            } else {
                self.setState(history.state, true);
            }
         }
    });
}

