$(document).ready(function()
{
    var share_timeout = null;
    var start_event = 'mouseenter touchstart';
    var end_event = 'mouseleave touchend';

    $('#container').on(start_event, '.share-button', function() {
        if ($('.share-list').hasClass('hide-list')) {
            $('.share-list').removeClass('hide-list').addClass('show-list');
            $('.share-button').addClass('is-active');
        }
        if ($('.share-list').hasClass('show-list')) {
            clearTimeout(share_timeout);
        }
    });


    $('#container').on(end_event, '.share-button', function() {
        share_timeout = setTimeout(function() {
            $('.share-list').addClass('hide-list').removeClass('show-list');
            $('.share-button').removeClass('is-active');
        }, 300);
    });

    $('#container').on(start_event, '.share-list', function() {
        if ($('.share-list').hasClass('show-list')) {
            clearTimeout(share_timeout);
        }
    });

    $('#container').on(end_event, '.share-list', function() {
        share_timeout = setTimeout(function() {
            $('.share-list').addClass('hide-list').removeClass('show-list');
            $('.share-button').removeClass('is-active');
        }, 300);
     });
});
