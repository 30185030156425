// de-obfuscate email
$(function()
{
    $("a[data-ob]").each(function()
    {
        var a = $(this);
        
        var data = a.data('ob');
        a.attr('data-ob', null);

        if (data.length == 2) {
            if (data[0].length) {
                a.prop('href', 'mailto:' + data[0].join('@'));
            }
            if (data[1].length) {
                a.html(data[1].join('@'));
            }
        }
    });
});
