jqueryForm.addEnhancement(function()
{
    if (!window.jqueryCalendar || !window.jqueryDialog) {
        return;
    }

    var input = document.createElement('input');
    try {
        input.type = 'date';
    } catch (e) {}
   
    /* Date is already supported no need to pollyfill
    */
    if (input.type == 'date') {
        return;
    }

    // ensure calendar is not added twice
 //   $('input[type="date"]:only-child', this.form).each(function()
    $('input[type="date"]', this.form).each(function()
    {
        if ($(this).next(".form-date-calendar-button").length > 0) {
            return;
        }

        var id = $(this).prop('id');
        $(this)
        .addClass('date-emulation')
        .after('<span class="form-date-calendar-button" data-for="' + id + '"></span>');
    });

    // everything after this can only fire once
    if (this.form.data('enhanced')) {
        return;
    }

    this.form.on('keypress', 'input[type="date"]', function(evt)
    {
        // skip control characters or back space
        if (evt.which === 0 || evt.which == 8 || evt.ctrlKey || evt.metaKey || evt.altKey) {
            return;
        }

        // not a number or a -
        if (evt.which != 45 && (evt.which < 48 || evt.which > 57)) {
            evt.preventDefault();
            return;
        }

        // can't get the characters in keyup event so use this workaround
        var char = String.fromCharCode(evt.which);
        var value = this.value + char;
        if (value.match(/^[0-9]{4}$/)) {
            evt.preventDefault();
            this.value += char + "-";
        } else if (value.match(/^[0-9]{4}-[0-9]{2}$/)) {
            evt.preventDefault();
            this.value += char + "-";
        }
    });

    this.form.on('input', 'input[type="date"]', function(evt)
    {
        this.value = this.value.replace(/[^0-9-]+/, "").replace(/[-]{2,}/, "-");
    });

    this.form.on('click', '.form-date-calendar-button', function(evt)
    {
        var button = $(this);
        var input = button.data('for') ? $('#' +  button.data('for')) : button.prev('input');
    
        if (!input.length) {
            return;
        }

        var offset = button.height() / 2;
        button.dialogOpen('', {
            'stem' : [offset - 5, 5, 5, 10],
            'position' : 'right-top',
            'id' : 'form-date-picker'
        }).afterOpen(function(body)
        {
            var opt = input.data("calendar");
            
            if (typeof(opt) != "object") {
                opt = {};
            }

            var calendar = new jqueryCalendar(body, opt);
            calendar.append();        

            calendar.node.on('click', 'tbody td', function()
            {
                var oldValue = input.prop('value');

                var date = new Date($(this).data('date'));
                var year  = date.getFullYear();
                var month = date.getMonth() + 1;
                var day   = date.getDate();

                var iso =  year + '-' + (month <= 9 ? '0' : '' ) + month;
                iso += '-' + (day <= 9 ? '0' : '') + day;

                if (iso != oldValue) {
                    input.prop('value', iso);
                    input.trigger('input');
                    input.trigger('change');
                }
                input.focus().select();

                button.dialogClose();
            });
        });
    });
});
