/*------------------------------------*\
    Admin List Priority used on the front end occasionally in inline lists
\*------------------------------------*/


function sbListPriority(selector)
{
    $.extend(this, new SbEvents());

    var $cont = $(selector);
    var self = this;
    var tr, lastTr;

    $cont.on('click', '.sb-admin-list-priority', function(evt)
    {
        evt.stopPropagation();
        evt.preventDefault();
    });

    $cont.on('mousedown', '.sb-admin-list-priority', function(evt)
    {
        evt.stopPropagation();
        evt.preventDefault();

        tr = $(evt.target).closest('tr')[0];
        $(tr).addClass('selected');
        $(document.body).css('user-select', 'none');
    });

  //  $cont.on('mouseup', '.sb-admin-list-priority', function(evt)
    $(document).on('mouseup', function(evt)
    {
        if (!tr) {
            return;
        }

        evt.stopPropagation();
        evt.preventDefault();

        var data = [];
        var tbody = tr.parentNode;
        
        for (var i = 0, row; (row = tbody.rows[i]); i++) {
            // take id from TD for node_relationships
            row = $(row);
            var td = $(".sb-admin-list-priority", row);
            data.push(td.data('id') || row.data('id'));
        }

        var post = new FormData();
        post.append('priority', data.join(','));    

        if (self.path) {
            SB.post(self.path, {
                'priority' :data.join(','),
                'csrf_token': self.csrf_token || ""
            })
            .then(function()
            {
               // alert(this.responseText);
            }, 
            function(e)
            {
                alert(e.message);
            });
        }

        $(tr).removeClass('selected');
        tr = null;
        $(document.body).css('user-select', '');
       
        self.trigger("mouseup", data);
    });

    $('tbody', $cont).on('mouseenter', 'tr', function(evt)
    {
        if (!tr) {
            return;
        }

        if (this === tr) {
            return;
        }

        // Hack for IE 11 which was double firing this event when dragging up undoing row ordering
        var currentTr = this;
        setTimeout(function()
        {
            if (currentTr === lastTr) {
                lastTr = null;
            }
        }, 20);
        
        if (this === lastTr) {
            return;
        }
        lastTr = this;
        // End IE 11 hack

        if (this.rowIndex < tr.rowIndex) {
            $(tr).after(this);
        } else {
            $(this).after(tr);
        }

        var data = {
            replace: $(tr).index(),
            with: $(this).index(),
        };

        self.trigger("change", data);
    });
}

