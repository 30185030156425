class InlineList
{
    constructor(selector, data)
    {
        this.rows = [];
        this.cont = $(selector);
        this.field = this.cont.find("input");
        this.data = data;

        this.rows = []; // store data here when new entry

        this.renderCont();
        this.events();
    }

    renderCont()
    {
        var html = `
            <table class="sb-admin-list-table" style="margin: 0;">
                <thead>
                    <tr>${ this.renderHead() }</tr>
                </thead>
                <tbody>
                    ${ this.renderRows() }
                </tbody>
                <tfoot>
                    ${ this.renderNew() }
                </tfoot>
            </table>
        `;

        this.cont.append(html);

        if (this.data.multilingual == false || this.data.is_default_language) {
            for (var key in this.data.fields) {
                var field = this.data.fields[key];
                this.fieldScripts(this.cont.find("tfoot"), field);
            }
        }

    }

    renderHead()
    {
        var fields = this.data.fields;

        var head = '';
        if (this.data.has_priority) {
            head += '<td  class="sb-admin-list-head-priority">Order</td>';
        }

        // for (var i = 0, field; field = fields[i]; i++) {
        //for (var field of new Map(fields)) {
        for (var key in fields) {
            var field = fields[key];
            head += `<td>${field.title}</td>`;
        }

        if (this.data.has_edit_form) {
            head += `<td class="sb-admin-list-head-edit"></td>`;
        }

        head += `<td class="sb-admin-list-head-delete"></td>`;

        return head;
    }

    renderRows()
    {
        var html = '';

        for (var i = 0, row; (row = this.data.rows[i]); i++) {
            html += this.renderRow(row);
        }
        return html;
    }

    renderRow(row)
    {
        var field = null, value = null;
        var fields = this.data.fields;
        var html = "";
        if (this.data.has_priority) {
            html += '<td class="sb-admin-list-priority"></td>';
        }

        for (var key in fields) {
            field = fields[key];
            value = typeof row[field.name] !== "undefined" &&  row[field.name] !== null  ? row[field.name] : '';
            var defaultLanguageValue = "";
            if (this.data.multilingual && this.data.is_default_language == false && value.length == 0) {
                defaultLanguageValue = row.defaults[field.name] || "";
            }

            let field_html = '';
            switch (field.type) {
            case 'checkbox':
                field.checked = field.value === row[field.name];
                field_html = this.renderField(field, (value.length ? value : defaultLanguageValue));
                break;
            default:
                field_html = this.formatField(field, (value.length ? value : defaultLanguageValue));
                break;
            }

            html += `<td ${SB.attr({
                class: SB.classes([
                    field.readonly || field.disabled ? "sb-admin-list-readonly" : "sb-admin-list-editable",
                    " pre",
                    defaultLanguageValue.length ? "sb-inline-list-default-language" : null,
                ]),
                "data-name": field.name,
                "data-value": row[field.name] || "",
                style: defaultLanguageValue.length ? "color: #999999;" : null,
            }) }>${ field_html }</td>`;
        }

        if (this.data.has_edit_form) {
            if ((typeof row._edit_link !== 'undefined') && row._edit_link) {
                html += `
                    <td class="sb-admin-list-edit">
                        <a href="${ row._edit_link }">
                            <button type="button" class="icon-button sb-admin-list-edit-button icon-edit"></button>
                        </a>
                    </td>
                `;
            } else {
                html += `<td></td>`;
            }
        }

        html += `
            <td class="sb-admin-list-delete">
                <button type="button" class="icon-button sb-admin-list-delete-button icon-delete"></button>
            </td>
        `;

        return `<tr data-id="${ row.id }">${ html }</tr>`;
    }

    renderNew()
    {
        if (this.data.multilingual && this.data.is_default_language == false) {
            return '';
        }

        var fields = this.data.fields;
        var html = "";

        if (this.data.has_priority) {
            html += '<td></td>';
        }

        for (var key in fields) {
            var field = fields[key];
            html += `<td>${ this.renderField(field, field.default)}</dt>`;
        }

        if (this.data.has_edit_form) {
            html += '<td></td>';
        }

        html += `<td><button type="button" class="sb-button sb-inline-list-add-button">Add</button></td>`;

        return `<tr class="sb-inline-list-new-row">${ html }</tr>`;
    }

    formatField(field, value)
    {
        if (field.type !== 'checkbox' && !value.length) {
            return "";
        }

        switch (field.type) {
        case 'time':
            var split = value.split(":");

            if (split.length == 3) {
                split.pop();
            }

            var hours = parseInt(split[0]);

            var suffix = 'am';
            if (hours > 12) {
                split[0] = (hours < 22 ? '0' : '') + (hours - 12);
                suffix = 'pm';
            }

            value = split.join(':') + ' ' + suffix;
            break;
        case 'select':
            value = field.option_list[value] || value;
            break;
        }
        return value;
    }

    renderField(field, value, row_id)
    {
        switch (field.type) {
        case 'time':
            var split = value.split(":");

            if (split.length == 3) {
                split.pop();
            }
            value = split.join(":");
            break;
        }

        var input = '';
        var readonly = field.readonly ? 'readonly="readonly"' : '';
        value = value === null ? '' : value;
        switch (field.type) {
        case 'text':
        case 'date':
        case 'time':
            input = `<input type="${ field.type }" data-name="${ field.name }" data-default="${ field.default }" value="${ value }" placeholder="${ field.placeholder || "" }" ${ readonly } />`;
            break;
        case 'checkbox':
            var checked = field.checked ? 'checked' : '';
            input = `<input type="${ field.type }" data-name="${ field.name }" data-default="${ field.default }" value="${ field.value }" placeholder="${ field.placeholder || "" }" ${ readonly } ${ checked } />`;
            break;
        case 'textarea':
            input = `<textarea data-name="${ field.name }" data-default="${ field.default }" placeholder="${ field.placeholder || "" }" ${ readonly }>${ SB.decodeAttr(value) }</textarea>`;
            break;
        case 'SMS':
            input = SB.dedent`
<div class="sms-field">
    <textarea data-name="${ field.name }" data-default="${ field.default }" ${ readonly }>${ SB.decodeAttr(value) }</textarea>
    <div class="sms-character-counter row">
        <div class="column small-3">Length: <span class="sms-message-length"></span></div>
        <div class="column small-3" style="display: none;">Messages: <span class="sms-message-count"></span> </div>
    </div>
</div>
            `;
            break;

        case 'select':
            if (field.readonly) {
                input = this.formatField(field, (value)) +
                    `<input type="hidden" data-name="${ field.name }" data-default="${ field.default }" value="${ value }" />`;
            } else {
                input = `<select data-name="${ field.name }" data-default="${ field.default }" ${ readonly }>`;
                for (var key in field.option_list) {
                    var selected = ((value !== null) && (key == value)) ? ' selected' : '';
                    input = input + `<option value="${ key }" ${ selected }>${ field.option_list[key] }</option>`;
                }
                input = input + '</select>';
            }
            break;

        case 'sblib':
            // XXX Started working on this but then backed away. Not
            // sure this is the direction we need to go here.
            // console.log(field);
            // var library = new SbLib(field.sblib.sblib);
            // var id = row_id || 0;
            // var selector = 'sblib-field-' + field.name + '-' + id;

            // library.action("rte-field", {id: id}, function(data) {
            //     $('#' + selector).append(data.html);
            // });

            // input = `<div id="${ selector }"></div>`;
            break;
        }

        return input;
    }

    fieldScripts($cont, field)
    {
        switch (field.type) {
        case 'SMS':
            new SmsField($cont.find(".sms-field"));
            break;
        }
    }

    events()
    {
        var self = this;

        this.cont.on("keydown", ":input", function(evt)
        {
            if (evt.which != 13 || this.nodeName == 'TEXTAREA') {
                return;
            }

            evt.preventDefault();
            $(".sb-inline-list-add-button", self.cont).trigger("click");
        });

        this.cont.on("click", ".sb-inline-list-add-button", function()
        {
            var $list = $(".sb-inline-list-new-row :input", self.cont);
            var $first = $list.first();

            if ($first.val().length == 0) {
                $first.focus();
                return;
            }

            var row = {};
            $list.each(function()
            {
                var $input = $(this);
                var name = $input.data('name');
                var default_value = $input.data('default');

                if (name) {
                    var value = $input.val();
                    row[name] = value;
                    $input.val(default_value);
                }
            });

            var html = self.renderRow(row);

            var $row = $(html).appendTo($("tbody", self.cont));

            if (self.data.is_new) {
                self.rows.push(row);
                self.field.val(JSON.stringify(self.rows));
            } else {
                var post = row;
                post.action = "save-new";

                SB.post(self.data.path, post)
                .then(function(data)
                {
                    $row.data("id", data.id);
                },
                function(e)
                {
                    alert(e.message);
                    $row.remove();
                    $list.each(function()
                    {
                        var $input = $(this);
                        var name = $input.data('name');
                        if (name) {
                            $input.val(row[name]);
                        }
                    });
                });
            }

            $first.focus();
        });

        this.cont.on("click", ".sb-admin-list-delete-button", function()
        {
            var $tr = $(this).closest('tr');

            if (self.data.is_new) {
                $tr.remove();
                self.rows.splice($tr.index(), 1);
                self.field.val(JSON.stringify(self.rows));
            } else {

                var post = {
                    action: "remove",
                    id: $tr.data("id")
                };

                if (!post.id) {
                    return;
                }

                $tr.hide();
                SB.post(self.data.path, post)
                .then(function(data)
                {
                    $tr.remove();
                },
                function(e)
                {
                    alert(e.message);
                    $tr.show();
                });
            }
        });

        var priority = new sbListPriority(self.cont.find("table"));

        priority.on("change", function(evt, data)
        {
            if (self.data.is_new) {

                var tmp = self.rows[data.replace];
                self.rows[data.replace] = self.rows[data.with];
                self.rows[data.with] = tmp;

                self.field.val(JSON.stringify(self.rows));
            }
        });

        priority.on("mouseup", function(evt, data)
        {
            if (self.data.is_new) {
                return;
            }

            var $list = $("tbody tr", self.cont);
            var order = [];

            $list.each(function()
            {
                var $tr = $(this);
                order.push($tr.data("id"));
            });

            var post = {
                action: "reorder",
                order: order.join(",")
            };

            if (order.length < 2) {
                return;
            }

            SB.post(self.data.path, post)
            .then(function(data)
            {
            },
            function(e)
            {
                alert(e.message);
            });
        });


        this.cont.on("click", ".sb-admin-list-editable", function()
        {
            var $td = $(this);
            var $input = $td.find(":input");
            var input_type = $input.attr('type');

            if ($input.length && input_type !== 'checkbox') {
                return;
            }

            var name = $td.data("name");
            var value = $td.data("value");
            var fields = self.data.fields;
            var field = fields[name];

            if (field.readonly || field.disabled) {
                return;
            }

            var $tr = $td.closest("tr");

            var row_id = $tr.data("id");

            if (input_type !== 'checkbox') {
              $td.html(self.renderField(field, value, row_id));
            } else {
              field.checked = $input[0].checked;
              $td.html(self.renderField(field, value, row_id));
            }

            $input = $td.find(":input");

            self.fieldScripts($td, field);

            if ($input.prop('nodeName') === 'SELECT') {
                $input.focus();
            } else {
                $input.select();
            }

            $input
            .on("keydown", function(evt)
            {
                if (evt.which == 13 && this.nodeName != 'TEXTAREA') {
                    evt.preventDefault();
                    $(this).blur();
                }
            })
            .on("blur", function()
            {
                if (this.type !== 'checkbox') {
                    if (this.value.length == 0) {
                        return;
                    }
                }

                if (self.data.is_new) {
                    if (this.type === 'checkbox') {
                        self.rows[$tr.index()][name] = this.checked ? this.value : null;
                    } else {
                        self.rows[$tr.index()][name] = this.value;
                    }

                    self.field.val(JSON.stringify(self.rows));
                    $td.text(this.value);
                } else {
                    let value = null;
                    let input = this;

                    if (input.type === 'checkbox') {
                      value = input.checked ? input.value : null;
                    } else {
                      value = input.value;
                    }

                    var post = {
                        action: "update",
                        id: $tr.data("id"),
                        name: name,
                        value: value
                    };

                    if (!post.id) {
                        return;
                    }

                    SB.post(self.data.path, post)
                    .then(function(data)
                    {
                        $td.data("value", post.value);

                        if (input.type !== 'checkbox') {
                            $td.text(self.formatField(field, post.value));
                        } else {
                            field.checked = input.checked;
                            $td.html(self.renderField(field, post.value));
                        }
                    },
                    function(e)
                    {
                        alert(e.message);
                    });

                }
            });
        });
    }
}

