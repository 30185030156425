(function($)
{
    $.fn.responsiveSlideshow = function(options)
    {
        return this.each(function()
        {
            var ul = $(this);
            var list = ul.children('li');

           // ul.find('*').prop('draggable', false);

          //  list.each(function(i,li){ul.prepend(li)})

            var Slideshow = new ResponsiveSlideshow(ul);
    
            ul.data('responsive-slideshow', Slideshow);
    
    /*
            Slideshow.addButtons();
            Slideshow.start();
    */
        });
    };


})($);

function ResponsiveSlideshow(ul, options)
{
    this.options = {
        'child' : 'li'
    };

    if (options) {
        $.extend(this.options, options);
    }
    
    var self = this;
    this.ul = ul = $(ul);
    this.delay = 6000;
    this.status = null;

    var list = this.ul.children(this.options.child);

/*
    if (list.length <= 1) {
        return;
    }
*/

    this.calculate = function()
    {
        var list = ul.children('li');
        list.finish();

        ul.css({
            'width' : 'auto',
            'height' : 'auto'
        });

        list.css({
            'display' : 'none',
            'position' : 'absolute',
            'width' : ul.width(),
            'top' : 0,
            'left' : 0
        });

        list.first().css({
            'display' : 'block'
        });

        var maxWidth = 0;
        var maxHeight = 0;

        list.each(function(i)
        {
            var li = $(this);
            if (li.height() > maxHeight) {
                maxHeight = li.height();
            }

            if (li.width() > maxWidth) {
                maxWidth = li.width();
            }
        });

        list.each(function()
        {
            var li = $(this);
            var top = (maxHeight - li.height()) / 2;
            li.css('top', top);
        });

        //list.height(maxHeight);
        
        ul.css({
            'display' : 'block',
          //  'width' : '100%',
          //  'width' : maxWidth,
            'height' : maxHeight
        });
    };

    $(window).on('resize orientationchange', function()
    {
        self.calculate();
    });

    //convert to a load event
    $(window).on('load', function()
    {
        self.calculate();
    });

    ul.css({
        'position' : 'relative',
        'overflow' : 'hidden',
        'list-style' : 'none'
    });

    list.css({
        'display' : 'none',
    });

    list.first().css({
        'display' : 'block'
    });


    $(".image > a", ul).each(function()
    {
        var a = $(this);
        var li = $(this).closest('li');
        
        li.css('cursor', 'pointer');

        a.on('click', function(evt)
        {
            evt.preventDefault();
        });

        li.on('click', function()
        {
            window.location.href = a.prop('href');
        });
        
    });

    ul.on('dragstart', '*', function(evt)
    {
        evt.preventDefault();
        evt.stopPropagation();
    })
    .on('mouseenter', function()
    {
        if (self.status == 'playing') {
            self.status = 'paused';
        }
    })
    .on('mouseleave', function()
    {
        if (self.status == 'paused') {
            self.start();
        }
    })
    .on('touchstart', 'li', function(evt)
    {
        self.originalScrollTop = $(window).scrollTop();
        var e = evt.originalEvent;
        var touches = e.targetTouches;

        if (touches.length > 1) {
            return;
        }

        var list = ul.children('li');
        if (list.length <= 1) {
            return;
        }


    /*
        evt.preventDefault();
        evt.stopPropagation();
    */

        self.firstMove = true;
        
        self.touchLI = ul.children('li').first();
        self.touchLI2 = null;

        if (self.touchLI.is(':animated')) {
            self.touchLI = null;
            return;
        }

        var touch = touches[0];
        
        var pos = ul.offset();
        
        self.touchX = touch.pageX - pos.left;
        self.touchY = touch.pageY - pos.top;

        self.startPageX = self.lastPageX = touch.pageX;
        self.startPageY = self.lastPageY = touch.pageY;

        self.pause();
    });

    $(document)
    .on('touchmove', 'li', function(evt)
    {
        var e = evt.originalEvent;
        var touches = e.targetTouches;

        if (touches.length > 1 || self.touchLI === null) {
            return;
        }

        var touch = touches[0];

        // IOS Safari can't handle scrolling and swiping at the frist time. Here we pick one or the other. 
        if (self.firstMove && Math.abs(touch.pageY - self.startPageY) > Math.abs(touch.pageX - self.startPageX)) {
            self.touchLI = null;
            return;
        }
        self.firstMove = false;

        self.lastPageY = touch.pageY;
        self.lastPageX = touch.pageX;

        var left = - (self.startPageX - touch.pageX);
        self.touchLI.css('left', left);

        var list = ul.children('li');
        var li2;
        if (left > 0) {
            li2 = list.last();
            li2.css({
                'display' : 'block',
                'left' : left - li2.width(),
            }); 
        } else {
            li2 = self.touchLI.next();
            li2.css({
                'display' : 'block',
                'left' :  left + self.touchLI.width(),
            }); 
        }

        evt.preventDefault();
        evt.stopPropagation();
    })
    .on('touchend', 'li', function(evt)
    {
        var e = evt.originalEvent;
      //  var touches = e.changedTouches;
        var touches = e.touches;

        if (touches.length > 0 || !self.touchLI) {
            return;
        }

        var left = parseInt(self.touchLI.css('left'));

        if (Math.abs(self.startPageX - self.lastPageX) === 0 && Math.abs(self.startPageY - self.lastPageY) === 0) {
            self.touchLI.trigger('click');
        } else if (Math.abs(self.startPageX - self.lastPageX) <= 100) {
            var li2;
            if (self.lastPageX > self.startPageX) {
                var list = ul.children('li');
                li2 = list.last();
            } else {
                li2 = self.touchLI.next();
            }
            var li = self.touchLI;

            li.animate({left : 0}, {
                duration : 200,
                easing : 'linear',
                progress : function(val, foo, bar)
                {

            //    li2.css('left', li.width() +  parseFloat(li.css('left')));
                    li2.css('left', parseFloat(li.css('left')) - li2.width());
                },
                'complete' : function()
                {
                    li2.css('display', 'none');
                }
            });

            evt.preventDefault();
            evt.stopPropagation();
        } else if (left > 0) {
            self.right();
            evt.preventDefault();
            evt.stopPropagation();
        } else if (left < 0) {
            self.left();
            evt.preventDefault();
            evt.stopPropagation();
        } 

        self.touchLI = null;
    });
    ul.disableUserSelect();

    this.start = function()
    {
        var list = ul.children('li');
        if (list.length <= 1) {
            return;
        }

        self.status = 'starting';
        if (this._startTimeout) {
            clearTimeout(this._startTimeout);
        }
        this._startTimeout = setTimeout(function()
        {
            self._startTimeout = null;
            if (self.status == 'starting') {
                self.play();
            }
        }, this.delay);
    };

    this.pause = function()
    {
        this.status = 'paused';
    };

    this.stop = function()
    {
        this.status = 'stopped';
    };

    this.play = function()
    {
        var list = ul.children('li');
        if (list.length <= 1) {
            return;
        }

        if (this.status == 'playing') {
            return;
        }
        this.status = 'playing';
        var direction = this.left;

        var callback = function()
        {
            if (self._playTimeout) {
                clearTimeout(self._playTimeout);
            }
            self._playTimeout = setTimeout(function()
            {
                self._playTimeout = null;
                if (self.status == 'playing') {
                    direction.call(self, callback);
                }
            }, self.delay);
        };

        direction(callback);
    };

    this.left = function(callback, userDuration)
    {
        var time = 700;
        var list = ul.children('li');
        var li = list.first('li');
        var li2 = li.next();

        var left = parseInt(li.css('left'));

        var duration =  userDuration || 700 * (1 - (Math.abs(left) / li.width()));

        $(self).trigger('change', li2);
        li.finish().animate({
            'left' : -li.width()
        }, {
            duration : duration,
            easing : 'linear',
            progress : function(val, foo, bar)
            {

                li2.css('left', li.width() +  parseFloat(li.css('left')));
            },
            'complete' : function()
            {
                ul.append(li);
                li.css('display', 'none');
                if (callback) {
                    callback();
                }
            }
        });

        li2.finish().css({
            'left' : li.width() + left,
            'display' : 'block'
        });
    };

    this.right = function(callback, userDuration)
    {
        var list = ul.children('li');
        var li = list.first('li');
        var li2 = list.last();
        var left = parseInt(li.css('left'));
        
        var duration =  userDuration || 700 * (1 - (left / li.width()));

        $(self).trigger('change', li2);
        li.finish().animate({
            'left' : li.width()
        }, { 
            duration : duration,
            easing : 'linear',
            progress : function(val, foo, bar)
            {

                li2.css('left', parseFloat(li.css('left')) - li2.width());
            },
            'complete' : function()
            {
                ul.prepend(li2);
                li.css('display', 'none');
                if (callback) {
                    callback();
                }
            }
        });

        li2.finish().css({
            'left' : -li.width() + left,
            'display' : 'block'
        });
    };

    this.addButtons = function()
    {
        var list = ul.children('li');
        if (list.length <= 1) {
            return;
        }
        
        var cont = $('<div class="responsive-slideshow-buttons"></div>');

        cont
    /*
        .on('mouseenter', function()
        {
            self.pause();
        })
    */
        .on('click', function(evt)
        {
            evt.stopPropagation();
        });

        $('<a class="responsive-slideshow-previous">Previous</a>')
        .appendTo(cont)
        .on('click', function()
        {
            self.previous();
        });        

        $('<a class="responsive-slideshow-next">Next</a>')
        .appendTo(cont)        
        .on('click', function()
        {
            self.next();
        });        

    /*
        $('<a class="responsive-slideshow-play">Play</a>')
        .appendTo(cont)        
        .on('click', function()
        {
            self.play();
        });        

        $('<a class="responsive-slideshow-payse">Pause</a>')
        .appendTo(cont)        
        .on('click', function()
        {
            self.pause();
        });        
    */

        ul.after(cont);
    };

    this.addControls = function()
    {
        var list = ul.children('li');
        if (list.length <= 1) {
            return;
        }
        
        var cont = $('<div class="responsive-slideshow-controls"></div>');

        cont
        .on('mouseenter', function()
        {
            self.pause();
        })
        .on('click', function(evt)
        {
            evt.stopPropagation();
        });
        
        $(this).on('change', function(evt, li)
        {
            cont.children('a').removeClass('current-slide');
            
            if (li) {
                $('a[data-id="' + li.id + '"]', cont).addClass('current-slide');
            }
        });

        list.each(function(i, li)
        {
            var a = $('<a>Slide ' + (i + 1) + '</a>');
            if (i === 0) {
                a.addClass('current-slide');
            }
            
            a.attr('data-id', li.id);

            cont.append(a);
        });

        ul.after(cont);

        var self = this;

        cont
        .on('click', 'a', function(evt)
        {
            if (ul.children('li:animated').length) {
                ul.children('li').finish();
            }

            var button = $(this);
            var current = cont.find('.current-slide');
            
            if (button[0] == current[0]) {
                return;
            }

            self.seek(button.index());

            evt.preventDefault();
        });
    };

    this.seek = function(index)
    {
        var current = $('.current-slide');
        self.stop();
        var diff, direction;
        if (index < current.index()) {
            diff = current.index() - index;
            direction = self.right;
        } else {
            diff = index - current.index();
            direction = self.left;
        }

        var duration = 700 / diff;
        var callback = function()
        {
            diff --;
            if (diff > 0) {
                direction.call(self, callback, duration);
            }
        };

        direction.call(self, callback, duration);
    };

    this.previous = function(callback)
    {
        this.stop();
        this.right(callback);
    };

    this.next = function(callback)
    {
        this.stop();
        this.left(callback);
    };

    this.calculate();
}


