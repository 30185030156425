function Slideshow(selector, options)
{
    this.options = {
        transition: "push", // push | fade
    };

    if (options) {
        $.extend(this.options, options);
    }
    
    this.cont = $(selector);
    var self = this;

    this.items = this.cont.find('.items');
    this.delay = 6000;
    this.status = null;

    var list = this.items.children(".item");

    // pause slideshow if in background
    $(document).on("visibilitychange", function()
    {
        if (document.hidden) {
            if (self.status == 'playing') {
                self.status = 'paused';
            }
        } else {
            if (self.status == 'paused') {
                self.start();
            }
        }
    });

    this.items
    .on('dragstart', '*', function(evt)
    {
        evt.preventDefault();
        evt.stopPropagation();
    })
    .on('mouseenter', function()
    {
        if (self.status == 'playing' || self.status == 'starting') {
           // self.status = 'paused';
            self.pause();
        }
    })
    .on('mouseleave', function()
    {
        if (self.status == 'paused') {
           self.start();
        }
    })
    .on('touchstart', '.item', function(evt)
    {
        self.cont.addClass("is-touch");
        self.touchLeft = self.touchRight = 0;
        
        self.originalScrollTop = $(window).scrollTop();
        var e = evt.originalEvent;
        var touches = e.targetTouches;

        if (touches && touches.length > 1) {
            return;
        }

       // this.touching = true; // used to cancel mouse events

        var list = self.touchList = self.items.children('.item');
        if (list.length <= 1) {
            return;
        }

        self.firstMove = true;
        
        self.touchItem = self.items.children('.item').first();

        if (TweenMax.isTweening(self.items)) {
            self.touchList = null; 
            self.touchItem = null;
            return;
        }

        var touch = touches[0];
        
    /*
        var pos = self.items.offset();
        self.touchX = touch.pageX - pos.left;
        self.touchY = touch.pageY - pos.top;
    */

        self.startPageX = self.lastPageX = touch.pageX;
        self.startPageY = self.lastPageY = touch.pageY;

        var current = self.touchCurrent = self.items.find('.current-slide');
        self.startIndex = current.index();

        self.pause();
    })
    .on('touchmove', '.item', function(evt)
    {
        var e = evt.originalEvent;
        var touches = e.targetTouches;

        if ((touches && touches.length > 1) || self.touchItem === null) {
            return;
        }

        var touch = touches[0];

        // IOS Safari can't handle scrolling and swiping at the frist time. Here we pick one or the other. 
        if (self.firstMove && Math.abs(touch.pageY - self.startPageY) > Math.abs(touch.pageX - self.startPageX)) {
            self.touchItem = null;
            return;
        }
        self.firstMove = false;

        self.lastPageY = touch.pageY;
        self.lastPageX = touch.pageX;

        var left = - (self.startPageX - touch.pageX);
        var offset = self.items.width() * self.startIndex - left;

        var current =  self.touchCurrent[0];
        var $list = self.touchList;
        var items = self.items[0];
        
        if (self.options.transition == 'push') {
            if (left > 0 && items.firstChild === current) {
                self.touchRight = true;
                self.items.prepend(list.last());
            } else if (left < 0 && items.lastChild === current) {
                self.touchLeft = true;
                self.items.append(list.first());
            }
     
            if (self.touchRight) {
                self.items.css('transform',  'translateX(-' + (current.offsetWidth + offset) + 'px)');
            } else if (self.touchLeft) {
                self.items.css('transform',  'translateX(-' + (-current.offsetWidth + offset) + 'px)');
            } else {
                self.items.css('transform',  'translateX(-' + offset + 'px)');
            }
        }

        self.items.data('left', left);

        evt.preventDefault();
        evt.stopPropagation();
    });

    $(document)
    .on('touchend', '.item', function(evt)
    {
        var e = evt.originalEvent;
      //  var touches = e.changedTouches;
        var touches = e.touches;

        if (touches.length > 0) {
            return;
        }

      //  this.touching = false; // used to cancel mouse events
        
        if (!self.touchItem) {
            return;
        }

        if (self.options.transition == 'push') {
            // undo changes made during touchmove
            self.touchList.detach();
            self.items.append(self.touchList);
        }
        
      //  var left = parseInt(self.touchItem.css('left'));
        var left = self.items.data('left') || 0;

        var offset = self.startPageX - self.lastPageX;
        if (Math.abs(offset) === 0 && Math.abs(self.startPageY - self.lastPageY) === 0) {
            if (self.options.transition == 'push') {
                self.touchItem.trigger('click');
            }
        } else if (Math.abs(offset) <= 70) {
            if (self.options.transition == 'push') {
                var index = self.startIndex;
                self.items.css('transform',  'translateX(-' + index * 100 + '%)');
            }
        } else if (left > 0) {
            self.right(null, offset);
            evt.preventDefault();
            evt.stopPropagation();
        } else if (left < 0) {
            self.left(null, offset);
            evt.preventDefault();
            evt.stopPropagation();
        } 

        self.touchItem = null;
        self.touchList = null;
    });

    this.start = function()
    {
        var list =  this.items.children('.item');
        if (list.length <= 1) {
            return;
        }

        self.status = 'starting';
        if (this._startTimeout) {
            clearTimeout(this._startTimeout);
            this._startTimeout = null;
        }
        this._startTimeout = setTimeout(function()
        {
            self._startTimeout = null;
            if (self.status == 'starting') {
                self.play();
            }
        }, this.delay);
    };

    this.pause = function()
    {
        this.status = 'paused';
    };

    this.stop = function()
    {
        this.status = 'stopped';
    };

    this.play = function()
    {
        var list = this.items.children('.item');
        if (list.length <= 1) {
            return;
        }

        if (this.status == 'playing') {
            return;
        }
        this.status = 'playing';
        var direction = this.left;

        var callback = function()
        {
            if (self._playTimeout) {
                clearTimeout(self._playTimeout);
                self._playTimeout = null;
            }
            self._playTimeout = setTimeout(function()
            {
                self._playTimeout = null;
        
                if (self.status == 'playing') {
                    direction.call(self, callback);
                }
            }, self.delay);
        };

        if (self._playTimeout) {
            clearTimeout(self._playTimeout);
            self._playTimeout = null;
        }

        if (self.status == 'playing') {
            direction.call(self, callback);
        }
    };

    this.left = function(callback, offset)
    {
        if (this.lastTween && this.lastTween.progress() < 1) {
            this.lastTween.progress(1);
        }
        this.lastTween = null;        

        this[this.options.transition + "Left"](callback, offset);
        $(self).trigger('change', list.filter(".current-slide"));
    };

    this.fadeLeft = function(callback)
    {
        var duration = 0.5;
        var $next;

        var $list = this.items.children('.item');
        $list.filter(".last-slide").removeClass("last-slide");
        var $current = $list.filter(".current-slide")
            .removeClass("current-slide")
            .addClass("last-slide")
        ;

        if ($list.last().is($current)) {
            $next = $list.first().addClass("current-slide");
        } else {
            $next = $current.next().addClass("current-slide");
        }

        if (self.status == 'playing' && callback) {
            callback();
        }

    /*
        this.lastTween = tl  = new TimelineLite();
        tl.to($current, duration / 2, {
            opacity: 0.5,
            onComplete: function()
            {
                self.items.css('transform',  'translateX(-' + ($next.index() * 100) + '%)');
                $current.css('opacity', 1);
                $next.css('opacity', 0.5);
            },
        })
        .to($next, duration / 2, { opacity: 1});
    */
    
    };
 
    this.pushLeft = function(callback, offset)
    {
        var duration = 1;

        var list = this.items.children('.item');
        var current = list.filter(".current-slide");
        current.removeClass("current-slide");

        var index = current.index();
        var width = list.first().width();
        if (!offset) {
            offset = 0;
        } else {
            duration = duration * (1 - Math.abs(offset) / width);        
        }

        if (index + 1 < list.length) {
            current.next().addClass("current-slide");

            this.lastTween = TweenLite.fromTo(this.items, duration,
                {
                    transform: 'translateX(-' + (((index ) * width) + offset) + 'px)',
                },
                {
                    transform: 'translateX(-' + (index + 1) * width + 'px)',
                    onComplete: function()
                    {
                        self.items.css('transform', 'translateX(-' + (index +1) * 100 + '%)');
                        if (self.status == 'playing' && callback) {
                            callback();
                        }
                    }
                }
            );
        } else {
            var first = $(list.get(0)).addClass("current-slide");
            self.items.append(first);
            this.lastTween = TweenLite.fromTo(this.items, duration, 
                {
                    transform: 'translateX(-' + (((index - 1) * width) + offset) + 'px)',
                  //  transform: 'translateX(' + -offset + 'px)'
                },
                {
                  //  transform: 'translateX(-' + width + 'px)',
                  //  transform: 'translateX(-100%)',
                    transform: 'translateX(-' + (index * width) + 'px)',
                    onComplete: function()
                    {
                        self.items.prepend(first);
                        self.items.css('transform', 'translateX(0%)');
                        if (self.status == 'playing' && callback) {
                            callback();
                        }
                    }
                }
            );
        }
    };


    this.right = function(callback, offset)
    {
        if (this.lastTween && this.lastTween.progress() < 1) {
            this.lastTween.progress(1);
        }
        this.lastTween = null;        

        this[this.options.transition + "Right"](callback, offset);
        $(self).trigger('change', list.filter(".current-slide"));
    };

    this.fadeRight = function(callback)
    {
        var duration = 0.5;
        var $prev;

        var $list = this.items.children('.item');
        $list.filter(".last-slide").removeClass("last-slide");
        var $current = $list.filter(".current-slide")
            .removeClass("current-slide")
            .addClass("last-slide")
        ;

        if ($current.index() == 0) {
            $prev = $list.last().addClass("current-slide");
        } else {
            $prev = $current.prev().addClass("current-slide");
        }

        if (self.status == 'playing' && callback) {
            callback();
        }

    /*
        this.lastTween = tl  = new TimelineLite();
        tl.to($current, duration / 2, {
            opacity: 0.5,
            onComplete: function()
            {
                self.items.css('transform',  'translateX(-' + ($prev.index() * 100) + '%)');
                $current.css('opacity', 1);
                $prev.css('opacity', 0.5);
            },
        })
        .to($prev, duration / 2, { opacity: 1});
    */
    };

    this.pushRight = function(callback, offset)
    {
        var duration = 1;
        if (this.lastTween &&  this.lastTween.progress() < 1) {
            this.lastTween.progress(1);
        }
        this.lastTween = null;        


        var list = this.items.children('.item');

        var current = list.filter(".current-slide");
        current.removeClass("current-slide");

        var index = current.index();
        var width = list.first().width();

        if (!offset) {
            offset = 0;
        } else {
            duration = duration * (1 - Math.abs(offset) / width);        
        }

        if (index > 0) {
            current.prev().addClass("current-slide");

            this.lastTween = TweenLite.fromTo(this.items, duration,
                {
                    transform: 'translateX(-' + (((index) * width) + offset) + 'px)',
                },
                {
                    transform: 'translateX(-' + (index - 1) * width + 'px)',
                    onComplete: function()
                    {
                        self.items.css('transform', 'translateX(-' + (index - 1) * 100 + '%)');
                        if (self.status == 'playing' && callback) {
                            callback();
                        }
                    }
                }
            );
        } else {
            var last = $(list.get(list.length - 1)).addClass("current-slide");

            // put last slide at the beginning for transition
            self.items.prepend(last);
            
            this.lastTween = TweenLite.fromTo(this.items, duration, 
                {
                   // transform: 'translateX(-' + (((list.length - 1) * width) + offset) + 'px)'
                    transform: 'translateX(-' + (width - offset) + 'px)'
                },
                {
                  //  transform: 'translateX(-' + (list.length - 2) * width +'px)',
                    transform: 'translateX(0px)',
                    onComplete: function()
                    {
                        self.items.append(last);
                        self.items.css('transform', 'translateX(-' + (list.length - 1) * 100 +'%)');
                        if (self.status == 'playing' && callback) {
                            callback();
                        }
                    }
                }
            );
        }
    };

    this.hoverEvents = function()
    {
        var self = this;

        $(".slideshow-previous", self.cont)
        .on("click", function()
        {
            self.previous();            
        });
            
        $(".slideshow-next", self.cont)
        .on("click", function()
        {
            self.next();
        });

    };

    this.thumbnailEvents = function(selector)
    {
        var cont = $(selector);
        var thumbnails = cont.find('LI');
        if (thumbnails.length <= 1) {
            return;
        }

        thumbnails.first().addClass('current-slide');

        cont
        .on('mouseenter', function()
        {
            if (self.status == 'playing' || self.status == 'starting') {
                self.pause();
            }
        })
        .on('click', function(evt)
        {
            evt.stopPropagation();
        });
        
        $(this).on('change', function(evt, item)
        {
            thumbnails.removeClass('current-slide');
            
            if (item) {
                $('LI[data-id="' + item.id + '"]', cont).addClass('current-slide');
            }
        });


        var self = this;

        cont
        .on('click', 'LI', function(evt)
        {
        /*
            if (ul.children('li:animated').length) {
                ul.children('li').finish();
            }
        */

            var button = $(this);
            var current = cont.find('.current-slide');
            
            if (button[0] == current[0]) {
                return;
            }

            self.seek(button.index());

            evt.preventDefault();
        });
    };

    this.seek = function(index)
    {
        self.stop();
        var list = this.items.children('.item');
        var current = list.filter('.current-slide');
        
    /*
        if (current.index() == index) {
            return;
        }
    */

        var slide = $(list.get(index));
        list.filter(".last-slide").removeClass("last-slide");
        current
            .removeClass("current-slide")
            .addClass("last-slide")
        ;        


        if (self.options.transition == 'push') {
            var width = list.first().width();
            if (index < current.index()) {
                TweenLite.to(this.items, 1,  {
                    transform: 'translateX(-' + index * width + 'px)',
                     onComplete: function()
                    {
                        self.items.css('transform', 'translateX(-' + index * 100 + '%)');
                    }
                });
            } else {
                TweenLite.to(this.items, 1,  {
                    transform: 'translateX(-' + index  * width + 'px)',
                     onComplete: function()
                    {
                        self.items.css('transform', 'translateX(-' + index  * 100 + '%)');
                    }
                });
            }
        }

        slide.addClass("current-slide");

        $(self).trigger('change', slide);
    };

    this.previous = function(callback)
    {
        this.stop();
        this.right(callback);
    };

    this.next = function(callback)
    {
        this.stop();
        this.left(callback);
    };
}


